// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-bag-js": () => import("/opt/build/repo/src/templates/bag.js" /* webpackChunkName: "component---src-templates-bag-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asi-js": () => import("/opt/build/repo/src/pages/asi.js" /* webpackChunkName: "component---src-pages-asi-js" */),
  "component---src-pages-bags-js": () => import("/opt/build/repo/src/pages/bags.js" /* webpackChunkName: "component---src-pages-bags-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dc-js": () => import("/opt/build/repo/src/pages/dc.js" /* webpackChunkName: "component---src-pages-dc-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/opt/build/repo/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-pm-js": () => import("/opt/build/repo/src/pages/pm.js" /* webpackChunkName: "component---src-pages-pm-js" */),
  "component---src-pages-sage-js": () => import("/opt/build/repo/src/pages/sage.js" /* webpackChunkName: "component---src-pages-sage-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

